<template>
  <div class="asklib" v-loading="loading" element-loading-text="拼命加载中">
    <div class="content flex">
      <div class="ask_left">
        <!--mescroll滚动区域的基本结构-->
        <ul class="list">
          <li class="box relative" v-for="(n, i) in list" :key="i">
            <div class="relative">
              <h2 @click="goRouter('/asklib/askDetail', n.id)" class="line_clamp1">
                {{ n.askTitle }}
              </h2>
              <span class="time">{{ n.createDate.slice(0, 10) }}</span>
              <div
                class="avatar cursorP"
                @click="
                  goRouter('/asklib/askCenter', n.memberId, n.isAnonymity)
                "
              >
                <img
                  :src="n.isAnonymity == 0 ? n.askPhoto : null"
                  alt=""
                  class="err_avatar"
                />
                <span>{{ n.isAnonymity == 0 ? n.askName : "匿名用户" }}</span>
              </div>
              <div class="text flex">
                <viewer
                  :images="JSON.parse(n.pic1)"
                  class="inline_block viewer"
                  v-if="JSON.parse(n.pic1)"
                >
                  <img
                    :src="item"
                    v-for="(item, index) in JSON.parse(n.pic1)"
                    :key="index"
                  />
                </viewer>
                <span @click="goRouter('/asklib/askDetail', n.id)">{{
                  n.content
                }}</span>
              </div>
              <p>
                <span
                  ><img src="~/static/ask/read.png" alt="阅读量" />{{
                    n.viewNumber
                  }}阅读</span
                >
                <span
                  ><img src="~/static/ask/comments.png" alt="回答量" />{{
                    n.answerNumber
                  }}回答</span
                >
                <span
                  ><img src="~/static/ask/focus.png" alt="关注量" />{{
                    n.collectNumber
                  }}关注</span
                >
              </p>
            </div>
          </li>
        </ul>
        <div
          class="ask_more"
          v-if="isMore"
          @click.stop="info"
          v-loading="loading"
        >
          点击加载更多
        </div>
      </div>
      <div class="ask_right">
        <div title="我要提问" class="quiz flex" @click="question">
          <h3>我要提问</h3>
        </div>
        <div class="ad relative" v-for="(n, i) in adList" :key="i">
          <a :href="n.picUrl" :title="n.adName">
            <img :src="n.picSrc" alt="" :title="n.adName" />
            <!--<div class="type">-->
            <!--课程-->
            <!--</div>-->
          </a>
        </div>
        <div class="title">大家都在关注</div>
        <ul class="list">
          <li
            v-for="(item, index) in rightList"
            :key="index"
            @click="goRouter('/asklib/askDetail', item.id)"
          >
            <h3 class="line_clamp1">{{ item.askTitle }}</h3>
            <div class="text line_clamp2">
              {{ item.content }}
            </div>
            <p>
              <span>{{ item.viewNumber }}阅读</span>
              <span>{{ item.answerNumber }}回答</span>
              <span>{{ item.collectNumber }}关注</span>
            </p>
          </li>
        </ul>
      </div>
    </div>
    <el-dialog
      title="我要提问"
      :visible.sync="dialog.dialogVisible"
      width="629px"
      :before-close="handleClose"
    >
      <div class="dialog_content">
        <img :src="dialog.avatar" alt="" class="img err_avatar" />
        <el-form ref="form" :model="form" :rules="err" class="inputs">
          <el-form-item prop="askTitle">
            <el-input
              type="text"
              v-model="form.askTitle"
              placeholder="请输入问题标题"
              class="name"
            ></el-input>
          </el-form-item>
          <div class="hide" v-if="form.askTitle.length">
            <el-form-item prop="content">
              <el-input
                type="textarea"
                rows="5"
                v-model="form.content"
                placeholder="详细描述您的问题"
                resize="none"
                maxlength="300"
                show-word-limit
              ></el-input>
            </el-form-item>
            <div class="upload">
              <el-upload
                class="avatar-uploader"
                action="/api/app-jycy-disabled/uploadImg"
                :headers="uploadHeaders"
                list-type="picture"
                :file-list="listImage"
                :limit="3"
                :on-success="handlePreview"
                :on-remove="handleRemove"
                :before-upload="beforeAvatarUpload"
              >
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="插入图片"
                  placement="bottom-start"
                >
                  <i class="el-icon-picture-outline avatar-uploader-icon"></i>
                </el-tooltip>
              </el-upload>
            </div>
          </div>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-checkbox v-model="form.isAnonymity">匿名</el-checkbox>
        <el-button
          type="primary"
          @click="addQuestion"
          :disabled="form.askTitle.length ? false : true"
          v-loading="loadings"
          >发布
        </el-button>
      </div>
    </el-dialog>
    <login-prompt
      :goShow="isHide"
      @close="handleClose"
      title="登录"
      text="登录成功后才可提问题！"
    ></login-prompt>
  </div>
</template>

<script>
import loginPrompt from "@/components/public/loginPrompt";

export default {
  name: "index",
  metaInfo: {
    title: "问答社区-兰州市残疾人就业创业网络服务平台",
    meta: [
      {
        name: "keywords",
        content: "残疾人问答,企业问答,残疾人问答互动,各地残疾人就业政策问答,",
      },
      {
        name: "description",
        content:
          "兰州市残疾人就业创业网络服务平台,问答社区版块为残疾人搭建一个相互沟通的“有温度”的栏目,残疾人用户和企业用户可以在该板块内提问有关残疾人就业创业的问题,企业也可以通过该板块提问有关招聘残疾人的问题。",
      },
    ],
  },
  components: {
    loginPrompt,
  },
  data() {
    return {
      list: [],
      total: "",
      rightList: [],
      adList: [],
      page: {
        pageNum: 0,
        pageSize: 10,
      },
      dialog: {
        dialogVisible: false,
        checked: "",
        imageUrl: "",
        avatar: "",
      },
      isHide: false,
      form: {
        askTitle: "",
        content: "",
        isAnonymity: 0,
        pic1: "",
      },
      err: {
        askTitle: [
          { required: true, message: "提问问题不能为空", trigger: "blur" },
        ],
        content: [
          { required: true, message: "详情描述不能为空", trigger: "blur" },
        ],
      },
      listImage: [],
      loading: false,
      loadings: false,
      isMore: false,
      isLogin: "",
    };
  },
  methods: {
    //初始化List
    info() {
      this.loading = true;
      this.page.pageNum += 1;
      this.$api
        .askListApi(this.page)
        .then((res) => {
          this.isMore = false;
          this.loading = false;
          this.list = this.list.concat(res.data.data.records);
          this.total = res.data.data.total;
          this.isLogin =
            this.$store.state.userType ||
            JSON.parse(localStorage.getItem("userType"));
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //最热回答
    hotInfo() {
      this.$api
        .askHotListApi({ pageNum: this.pageNum, pageSize: 5 })
        .then((res) => {
          // console.log(res.data.data)
          this.loading = false;
          this.rightList = res.data.data.records;
          // console.log(this.rightList)
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //打开问题校验登录
    question() {
      if (this.isLogin) {
        this.dialog.avatar = this.isLogin.logo;
        this.dialog.dialogVisible = true;
      } else {
        this.isHide = true;
      }
    },
    //广告位
    ad() {
      this.$api
        .adApi({ classId: "40288163734799820173479a4d640001" })
        .then((res) => {
          this.adList = res.data.data;
        });
    },
    //添加问题
    addQuestion() {
      this.form.pic1 = JSON.stringify(this.listImage);
      if (this.form.isAnonymity) {
        this.form.isAnonymity = 1;
      } else {
        this.form.isAnonymity = 0;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loadings = true;
          this.$api.addQuestionApi(this.form).then((res) => {
            this.loadings = false;
            if (res.data.success) {
              this.$message.success(res.data.msg);
              setTimeout(() => {
                this.dialog.dialogVisible = false;
              }, 1000);
            } else {
              this.$message.error(res.data.msg);
            }
          });
        }
      });
    },
    //滚动下拉
    scrollBottom() {
      // 变量scrollTop为当前页面的滚动条纵坐标位置
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      // 变量 windowHeight 是可视区的高度
      let windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      // 变量 scrollHeight 是滚动条的总高度
      let scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      if (scrollHeight - (scrollTop + windowHeight) < 300) {
        if (this.total != this.list.length) {
          console.log(this.total, this.list.length);
          this.isMore = true;
        } else {
          this.isMore = false;
        }
      }
    },
    //路由
    goRouter(url, id, type) {
      if (type == 0 || type == null) {
        const { href } = this.$router.resolve({
          path: url,
          query: {
            id: id,
          },
        });
        window.open(href, "_blank");
      }
      this.$api.readApi({ id: id }).then((res) => {});
    },
    handleClose() {
      this.dialog.dialogVisible = false;
      this.isHide = false;
    },
    beforeAvatarUpload(file) {
      let type = ["image/jpeg", "image/png"];
      const isImage = type.includes(file.type);
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isImage) {
        this.$message.error("上传图片格式不正确");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isImage && isLt2M;
    },
    handlePreview(file, fileList) {
      this.listImage.push(file.msg);
    },
    handleRemove(file) {
      this.listImage.pop(file.response.msg);
    },
  },
  created() {
    this.info();
    this.hotInfo();
    this.ad();
    //判断是否移动端
    this.$mobile(
        "https://lzmz.cdpee.org.cn/#/pages/community/index"
    );
  },
  mounted() {
    window.addEventListener("scroll", this.scrollBottom);
  },
};
</script>

<style scoped lang="less">
@import "~assets/css/ask.less";
/*通过fixed固定mescroll的高度*/
/deep/ .mescroll {
  position: fixed;
  top: 44px;
  bottom: 0;
  height: auto;
}

/deep/ .dialog_content {
  width: 590px;
  .img {
    width: 44px;
    height: 44px;
    margin-right: 25px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
  }

  .inputs {
    flex: 1;

    .name {
      margin-bottom: 20px;
    }

    .hide {
      .el-upload {
        margin-top: 10px;

        .avatar {
          width: 100px;
          height: 100px;
        }

        i {
          font-size: 18px;
          color: #333333;
        }
      }
    }
  }
}

/deep/ .dialog-footer {
  .el-checkbox {
    margin-right: 20px;

    .el-checkbox__label {
      color: #666666;
    }
  }

  .el-button {
    width: 100px;
  }

  .is-disabled {
    color: #666666;
    background: #f4f4f4;
    border: #f4f4f4 solid 1px;
  }
}
.asklib .content .ask_left .list .box .text{
  img{
    max-height: 200px;
    max-width: 400px;
  }
}
</style>
